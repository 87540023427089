import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import PropTypes from 'prop-types';

import CertificateIcon from '../images/certificate-icon.svg';
import FileIcon from '../images/file-icon.svg';
import PageIcon from '../images/page-icon.svg';
import ContractIcon from '../images/contract-icon.svg';
import Layout from '../layouts/index';
import DemoForm from '../components/DemoForm';
import Slider from '../components/Slider';

function TratoEducation({ location }) {
  const { t } = useTranslation();
  const { locale } = useLocalization();

  return (
    <Layout
      location={location}
      id="landing"
      locale={locale}
      bodyClass="page-home landing bg-grey trato-education"
      inverted
      border
      simpleLayout
    >
      <section className="bg-grey-xx">
        <div className="layout-image d-flex justify-content-center">
          <div className="d-flex flex-wrap align-content-center title text-light text-center font-weight-bold align-items-center">
            <span className="text-large">{t('tratoEducation.heroTitle')}</span>
            <span className="text-large">{t('tratoEducation.heroTitle0')}</span>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 d-flex">
              <div className="align-self-center p-3">
                <h3 className="font-weight-bold mb-3">
                  {t('tratoEducation.heroTitle1')}&nbsp;<br />
                  <span className="font-weight-bold underline-primary">{t('tratoEducation.heroTitle2')}</span>&nbsp;
                  {t('tratoEducation.heroTitle3')}
                </h3>
                <p className="text-primary font-weight-bold mb-3">{t('tratoEducation.subtitle')}</p>
                <p className="font-weight-normal mb-3">{t('tratoEducation.sectionOneText')}</p>
                <ul className="list-unstyled">
                  <li className="d-flex mb-4">
                    <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                      <div className="d-flex">
                        <img alt="" src={CertificateIcon} className="text-primary align-baseline icon-lg align-self-center" />
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-normal text-muted">{t('tratoEducation.feature1')}</span>
                    </div>
                  </li>
                  <li className="d-flex mb-4">
                    <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                      <div className="d-flex">
                        <img alt="" src={FileIcon} className="text-primary align-baseline icon-lg align-self-center" />
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-normal text-muted">{t('tratoEducation.feature2')}</span>
                    </div>
                  </li>
                  <li className="d-flex mb-4">
                    <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                      <div className="d-flex">
                        <img alt="" src={PageIcon} className="text-primary align-baseline icon-lg align-self-center" />
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-normal text-muted">{t('tratoEducation.feature3')}</span>
                    </div>
                  </li>
                  <li className="d-flex mb-4">
                    <div className="d-flex justify-content-center p-2 bg-light circle-orange-border mr-2">
                      <div className="d-flex">
                        <img alt="" src={ContractIcon} className="text-primary align-baseline icon-lg align-self-center" />
                      </div>
                    </div>
                    <div>
                      <span className="font-weight-normal text-muted">{t('tratoEducation.feature4')}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 mb-5">
              <div className="form p-5 mt-3">
                <div className="d-flex justify-content-center mb-3">
                  <span className="text-center font-weight-bold text-muted">{t('demoForm.subtitle')}</span>
                </div>
                <DemoForm onlyForm formButtonLabel={t('demoForm.button3')} type="trato-education" />
              </div>
            </div>
            <div className="col-12">
              {/* <Slider
                sliderTitle={t('tratoEducation.testimonialTitle')}
                elements={[
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                  {
                    text: t('tratoEducation.testimonialOneText'),
                    company: t('tratoEducation.testimonialOneCompany'),
                  },
                ]}
              /> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

TratoEducation.propTypes = {
};

export default TratoEducation;
